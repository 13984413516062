import { defineStore } from "pinia";
import { plainToClass } from "class-transformer";
import { Tag } from "@/models/tag";

export const useMasterDataStore = defineStore("masterData", () => {
  const dogRunTags = ref([]);
  const dogCafeTags = ref([]);
  const dogHotelTags = ref([]);
  const petSalonTags = ref([]);
  const campsiteTags = ref([]);

  async function fetchAll() {
    const [
      { tags: dogRunTagJson },
      { tags: dogCafeTagJson },
      { tags: dogHotelTagJson },
      { tags: petSalonTagJson },
      { tags: campsiteTagJson },
    ] = await Promise.all([
      apiFetch(`/tags`, { params: { tag_type: "dog_run_feature_tags" } }),
      apiFetch(`/tags`, { params: { tag_type: "dog_cafe_feature_tags" } }),
      apiFetch(`/tags`, { params: { tag_type: "dog_hotel_feature_tags" } }),
      apiFetch(`/tags`, { params: { tag_type: "pet_salon_feature_tags" } }),
      apiFetch(`/tags`, { params: { tag_type: "campsite_feature_tags" } }),
    ]);
    dogRunTags.value = plainToClass(Tag, dogRunTagJson);
    dogCafeTags.value = plainToClass(Tag, dogCafeTagJson);
    dogHotelTags.value = plainToClass(Tag, dogHotelTagJson);
    petSalonTags.value = plainToClass(Tag, petSalonTagJson);
    campsiteTags.value = plainToClass(Tag, campsiteTagJson);
  }

  return {
    dogRunTags,
    dogCafeTags,
    dogHotelTags,
    petSalonTags,
    campsiteTags,
    fetchAll,
  };
});
